<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="tag_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-tag-filter></app-tag-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="tags"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import TagFilter from '../../components/filter/FilterTag'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'

export default {
  name: 'TagListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('tag.list.id'),
          title: this.$t('tag.list.title')
        },
        actions: {
          edit: 'tag_edit'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    tags () {
      return this.$store.getters['tag/list']
    },
    totalCount () {
      return this.$store.getters['tag/totalCount']
    },
    page () {
      return this.$store.getters['tag/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appTagFilter: TagFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getTags () {
      this.$store.dispatch('tag/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('tag/setPage', page)
      this.getTags()
    }
  },
  mounted () {
    this.getTags()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
